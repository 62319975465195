import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

const asideMenu = {
    $item: $('.header-navigation-item'),

    hovering: function () {
        let container = this.$item.parent();
        let itemsList = container.find('.header-navigation-item');

        itemsList.each(function () {
            $(this).toggleClass('header-navigation-item_inactive');
        });
    },

    init: function () {
        let _this = this;
        let header = $('.header');

        this.$item.hover(function () {

            let itemWrapper = $(this).find('.header-navigation-wrapper');
            let subItem = $(this).find('.header-subitems');

            _this.hovering();
            $(this).removeClass('header-navigation-item_inactive');
            if(subItem.length > 0) {
                itemWrapper.toggleClass('header-navigation-wrapper_active')
                header.toggleClass('header_active');
            }
        });
    }
};

export default asideMenu;