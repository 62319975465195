import rangeSlider from '../modules/rangeSlider';

const initRangeSlider = () => {

  document.addEventListener('DOMContentLoaded', function () {
    let sliderElement = document.querySelector('.js-rangeSlider');
    let inputElements = [
      document.querySelector('.js-rangeSlider--min'),
      document.querySelector('.js-rangeSlider--max')
    ];


    new rangeSlider(sliderElement, inputElements);

  });
}

export default initRangeSlider;
