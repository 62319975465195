import $ from 'jquery';
import { gsap } from 'gsap';
import { CSSPlugin } from 'gsap/CSSPlugin';
import 'simplebar';
import 'simplebar/dist/simplebar.css';

gsap.registerPlugin(CSSPlugin);

import throttle from 'lodash.throttle';

import asideMenu from './modules/aside-menu';
import './modules/overlay';
import './modules/menu';
import toggle from './modules/toggle';
import './catalog';
import './modules/sliders';
import initTabs from './modules/tabs';
import './modules/select';
import Counter from './modules/counter';
import popupHelper from './modules/popupHelper';
import './modules/input-file';

document.addEventListener('DOMContentLoaded', ()=>{
    asideMenu.init();

    $('[data-toggle="init"]').each(function () {
        let button = $(this).children('[data-toggle="button"]');
        let area = $(this).children('[data-toggle="area"]');
        let text = Boolean(button.data('otext') || button.data('ctext'));
        new toggle(button, area, text);

        let complexTabs = document.querySelectorAll('.tabs-container');
        if (complexTabs.length > 0) {
            initTabs('.tabs-container');
        }
    });

    $(".header-search-button").click(function (e) {
        var inputLenght = $('.header-search-input').val();
        console.log(inputLenght);
        if (inputLenght == '' && $('.header-search').hasClass("header-search_active")){
            console.log(1);
            e.preventDefault();
            $('.header-search').removeClass("header-search_active")
        } else if (!$('.header-search').hasClass("header-search_active")) {
            console.log(2);
            e.preventDefault();
            $('.header-search').addClass("header-search_active")
        } else {
        }
    });

    let button = document.querySelector('.whatsapp-button');
    let whatsappElement = document.querySelector('.whatsapp-menu');
    let whatsappClose = document.querySelector('.whatsapp-menu-close');
    button.addEventListener('click', ()=>{
        whatsappElement.classList.add('whatsapp-menu_active')
    });
    whatsappClose.addEventListener('click', ()=>{
        if(whatsappElement.classList.contains('whatsapp-menu_active')){
            whatsappElement.classList.remove('whatsapp-menu_active')
        }
    })
});

document.addEventListener('DOMContentLoaded', function () {
    $('[data-fancybox="gallery"]').fancybox({});
    $('.js-phone').inputmask({mask: "+7 (999) 999-99-99"});
});

document.addEventListener('DOMContentLoaded', function () {

    let filterContainers = document.querySelectorAll('.catalog-filter-design__bottom');
    filterContainers.forEach((item)=>{

        let filterItem = item.querySelectorAll('.catalog-filter-design__subitem');
        console.log(filterItem.length)
        console.log(item)
        if(filterItem.length > 5){
            item.classList.add('catalog-filter-design__bottom_active')
        }
    })
});

document.addEventListener('DOMContentLoaded', function () {
    let  brandContainersSmall = document.querySelectorAll('.brands-description_small');

    if (window.matchMedia("(max-width: 1240px)").matches) {
        brandContainersSmall.forEach((item)=>{
            item.classList.remove('brands-description_small')
        })
    }
});

document.addEventListener('DOMContentLoaded', function () {
    $('.js-counter').each(function () {
        new Counter($(this));
    })
});

document.addEventListener('DOMContentLoaded', () => {
    let popup = document.querySelector('.popup');
    window.modal = {};
    window.modal = new popupHelper(popup);
});

document.addEventListener('DOMContentLoaded', () => {
    let consultationButton = document.querySelectorAll('.consultation-main-item__button');
    let consultationClose = document.querySelector('.consultation-booking__close');
    let form = document.querySelector('.consultation-booking');
    if(form) {
        consultationButton.forEach((item)=>{
            item.addEventListener('click', ()=>{
                form.classList.add('consultation-booking_active')
            })
        });

        consultationClose.addEventListener('click', ()=>{
            form.classList.remove('consultation-booking_active')

        })
    }
});

/*
document.addEventListener('DOMContentLoaded', ()=>{
    let cardDrops = document.querySelectorAll('card-body-item__wrapper');
    cardDrops.forEach((item)=>{
        new SimpleBar(item);
    })
})
*/


