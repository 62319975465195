import noUiSlider from 'nouislider';

class rangeSlider {
  constructor(sliderElement, inputElements = []) {
    this.sliderElement = sliderElement;
    this.inputElements = inputElements;
    this.MIN_VALUE = Number(sliderElement.dataset.min);
    this.MAX_VALUE = Number(sliderElement.dataset.max);
    console.log(123)

    this.step = Math.round(this.MAX_VALUE / 200);

    this.slider = noUiSlider.create(sliderElement, {
      start: [
        inputElements[0].value,
        inputElements[1].value
      ],
      connect: true,
      step: this.step,
      range: {
        'min': this.MIN_VALUE,
        'max': this.MAX_VALUE
      }
    });

    this.slider.on('update', (values, handle) => {
      this.update(values, handle);
    });

    this.inputElements.forEach((element, key) => {

      element.addEventListener('change', () => {
        this.change(element, key);
      });

      element.addEventListener('keydown', (event) => {
        this.keydown(event, element, key);
      });
    })
  }

  update(values, handle) {
    this.inputElements[handle].value = Math.round(values[handle]);
  }

  change(element, key) {
    this.slider.setHandle(key, element.value);
  }

  keydown(event, element, key) {
    const values = this.slider.get();
    const value = Number(values[key]);
    const steps = this.slider.steps();
    const step = steps[key];
    let position;
    switch (event.key) {
      case "Enter":
        this.slider.setHandle(key, element.value);
        break;
      case "ArrowUp":
        position = step[1];
        if (position === false) {
          position = 1;
        }
        if (position !== null) {
          this.slider.setHandle(key, value + position);
        }
        break;
      case "ArrowDown":
        position = step[0];
        if (position === false) {
          position = 1;
        }
        if (position !== null) {
          this.slider.setHandle(key, value - position);
        }
        break;
    }
  }
}

export default rangeSlider;
