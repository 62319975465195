import { Swiper, Pagination, Navigation, EffectFade, Autoplay, Thumbs } from 'swiper/js/swiper.esm';

Swiper.use([Pagination, Navigation, EffectFade, Autoplay, Thumbs]);

let cardSlider = document.querySelectorAll('.catalog-cards-tab');
cardSlider.forEach((item)=>{
    let prev = item.querySelector('.card-slider__prev');
    let next = item.querySelector('.card-slider__next');
    let options = {
        slidesPerView: 1,
        spaceBetween: 0,
        navigation: {
            prevEl: prev,
            nextEl: next
        },
        breakpoints: {
            1241: {
                slidesPerView: 6,
                spaceBetween: 20,
            },
            1025: {
                slidesPerView: 5,
                spaceBetween: 20,
            },
            781: {
                slidesPerView: 3,
            },
            481: {
                slidesPerView: 2,
                spaceBetween: 10,
            }
        }
    };


    let newSlider = item.querySelector('.card-slider');

    new Swiper(newSlider, options);
});

let cardThumbs = new Swiper('.card-body-thumb', {
    spaceBetween: 10,
    slidesPerView: 3,
    watchSlidesVisibility: true,
    watchSlidesProgress: true,
});
let cardTop = new Swiper('.card-body-slider', {
    spaceBetween: 10,
    thumbs: {
        swiper: cardThumbs
    }
});

let sectionSlider = document.querySelectorAll('.section-slider');
sectionSlider.forEach((item)=>{
    let prev = item.querySelector('.section-slider__prev');
    let next = item.querySelector('.section-slider__next');
    let options = {
        centeredSlides: false,
        slidesPerView: 1,
        spaceBetween: 138,
        navigation: {
            prevEl: prev,
            nextEl: next
        },
        breakpoints: {

            781: {
                centeredSlides: true,
                slidesPerView: 2,
            }
        }
    };


    let sectionSlider = item.querySelector('.section-slider__container');

    new Swiper(sectionSlider, options);
});

