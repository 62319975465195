$(".custom-select").each(function() {
    var classes = $(this).attr("class");

    var template =  '<div class="' + classes + '">';
    template += '<span class="icon-arrow-bottom custom-select-arrow"></span>';
    template += '<span class="custom-select-trigger">' + $(this).attr("data-holder") + '</span>';
    template += '<div class="custom-options">';
    $(this).find("option").each(function() {
        if($(this).data('size')){
            template += '<span class="custom-option ' + $(this).attr("class") + '" data-value="' + $(this).attr("value") + '">' + '<span class="custom-option__text">' + $(this).html() + '</span>' + '<span class="custom-option__num">' + $(this).attr("data-size") + '</span>' + '</span>';
        } else {
            template += '<span class="custom-option ' + $(this).attr("class") + '" data-value="' + $(this).attr("value") + '">' + '<span class="custom-option__text">' + $(this).html() + '</span>' + '</span>';
        }
    });
    if(!$(".custom-select").hasClass("basket")) {
        template += '<div class="custom-description"> Нет нужного размера? Мы можем оповестить вас, когда он снова будет в наличии.</div>';
    }

    template += '</div></div>';

    $(this).wrap('<div class="custom-select-wrapper">' + '</div>');
    $(this).hide();
    $(this).after(template);
});

$(".custom-option").hover(function() {
    $(this).addClass("option-hover");
}, function() {
    $(this).removeClass("option-hover");
});

$(".custom-select-trigger").on("click", function(evt) {

    $(this).parents(".custom-select").toggleClass("opened");
    evt.stopPropagation();

});

$(".custom-option").on("click", function() {
    $(this).parents(".custom-select-wrapper").find("select").val($(this).data("value"));
    $(this).parents(".custom-options").find(".custom-option").removeClass("selection");
    $(this).addClass("selection");
    $(this).parents(".custom-select").removeClass("opened");
    $(this).parents(".custom-select").find(".custom-select-trigger").text($(this).text());
});