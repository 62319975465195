class Counter {
  constructor($element) {
    this.$input = $element.find('.js-counterValue');
    this.count = Number(this.$input.val());
    this.$plus = $element.find('.js-counterIncrement');
    this.$minus = $element.find('.js-counterDecrement');
    this.$show = $element.find('.js-counterShow');
    this.maximum = Number($element.data('max'));
    this.minimum = Number(this.$input.attr('min'));
    this.$reset = $element.find('.js-counterReset');

    this.init();
  }

  increment() {
    this.count = (this.count === this.maximum) ? this.maximum : ++this.count;
  }

  decrement() {
    this.count = (this.count === this.minimum) ? this.minimum : --this.count;
  }

  reset() {
    this.count = 0;
  }

  set() {
    this.$input.val(this.count);
    this.$show.text(this.count);

    if (this.count > 0) {
      this.$reset.show();
    } else {
      this.$reset.hide();
    }
  }

  init() {
    this.$plus.click(() => {
      this.increment();
      this.set();
    });

    this.$minus.click(() => {
      this.decrement();
      this.set();
    });

    // this.$reset.click(() => {
    //   this.reset();
    //   this.set();
    // })
  }

}

export default Counter;
