class Tab {
    constructor(tabList, toggle, tab) {
        this.tabList = tabList;
        this.toggle = toggle;
        this.tab = tab;
        this.init();
    }

    init() {
        if (this.toggle.classList.contains('tabs__toggle_active')) {
            this.open();
        } else {
            this.close();
        }

        this.toggle.addEventListener('click', () => {
            this.open();
        });
    }

    open() {
        if (this.tabList.active === this) {
            return;
        }
        if (this.tabList.active) {
            this.tabList.active.close();
        }
        this.tabList.active = this;
        this.tab.style.display = 'flex';
        this.toggle.classList.add('tabs__toggle_active');
    }

    close() {
        this.tab.style.display = 'none';
        this.toggle.classList.remove('tabs__toggle_active');
    }
}

export class Tabs {
    constructor(container) {
        this.container = container;
        this.init();
    }

    init() {
        this.toggleList = this.container.querySelectorAll('.tabs__toggle');
        this.tabList = this.container.querySelectorAll('.tabs__tab');

        if (!this.checkValid()) {
            return;
        }

        this.toggleList.forEach((item, index) => {
            new Tab(this, item, this.tabList[index]);
        });
    }

    checkValid() {
        if (this.toggleList.length !== this.tabList.length) {
            return false;
        } else if (this.toggleList.length === 0) {
            return false;
        } else if (this.tabList.length === 0) {
            return false;
        }
        return true;
    }
}

export default function initTabs(selector) {
    for (let container of document.querySelectorAll(selector)) {
        new Tabs(container);
    }
}