import initRangeSlider from './catalog/initRangeSlider';

(() => {
  let catalogAside = document.querySelectorAll('.catalog-aside');
  if(catalogAside.length > 0) {
    initRangeSlider();
  }


  $('.catalog-aside__head').click(function () {
    showFilter();
  });

  $('.catalog-filter__close').click(function () {
    closeFilter();
    console.log(123)
  });
})();


const showFilter = () => {
  $('.catalog-aside').addClass('catalog-aside--open');
};

const closeFilter = () => {
  $('.catalog-aside').removeClass('catalog-aside--open');
};

